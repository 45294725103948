@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "lexend";
  src: url('../public/fonts/Lexend-VariableFont_wght.ttf') format('truetype');
}

body {
  font-family: -apple-system, lexend, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, .contact {
  font-size: 14px;
}

.copy a {
  font-size: 12px;
}